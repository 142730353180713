<template>
	<div class="announcedetails">
		<!-- 头部 -->
		<app-header @getHeight="getHeight" :phone="phone" />

		<div class="max_width min_width min_height">
			<div class="flex align_center padtb_20" :style="{'margin-top': offsetHeight + 'px'}">
				<div class="f838 size_14">当前位置：</div>
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item class="d8f">通知公告</el-breadcrumb-item>
					<!-- <el-breadcrumb-item :to="{ path: '/announcements' }">通知公告</el-breadcrumb-item>
					<el-breadcrumb-item class="d8f">详情</el-breadcrumb-item> -->
				</el-breadcrumb>
			</div>

			<section class="flex align_start jus_content marb_30">
				<!-- 左边 -->
				<div class="width_30 pad_30 bgfff">
					<div>
						<div class="border_title" style="font-size: 16px;">通知公告</div>


						<div class="flex align_center mart_20">
							<div class="width announ_details_search">
								<el-input placeholder="请输入关键词" v-model="keyword">
									<i slot="suffix" class="el-icon-search" @click="searchData"></i>
								</el-input>
							</div>
						</div>
					</div>

					<!-- 列表 -->
					<div class="mart_30">
						<div class="announcedetails_infinite"
							:class="{'flex': announceList.length == 0, 'align_center': announceList.length == 0, 'jus_center': announceList.length == 0}">
							<div class="infinite-list-wrapper" style="overflow:auto; height: 100%;"
								v-if='announceList.length > 0'>
								<ul class="list" v-infinite-scroll="infiniteData" infinite-scroll-disabled="disabled">
									<li v-for="(item, index) in announceList"
										class="list-item padb_15 border_bottom mart_15"
										:style="{'margin-top': index == 0 ? '0px' : ''}" @click="setInfo(item.id)">
										<div class="size_15 ttt text_line2 font cursor" :title="item.title"
											:style="{'color': qid == item.id ? '#0D8EFF' : ''}">{{item.title}}</div>
										<div class="c61 size_12 mart_20 time_font mart_10">发布时间：{{item.create_time}}
										</div>
									</li>
								</ul>
								<p v-if="loading" class="text_center c61 mart_10">加载中...</p>
								<p v-if="noMore" class="text_center c61 mart_10">没有更多了</p>
							</div>

							<el-empty description="暂无数据" v-if='empty' :image-size="250"></el-empty>
						</div>
					</div>
				</div>

				<!-- 右边 -->
				<div class="width_68 bgfff announcedetails_pad">
					<div class="flex column align_center width" style="min-height: 860px;">
						<div class="size_20 weight ttt">{{info.title}}</div>
						<div class="mart_20 flex align_center jus_center">
							<div class="c61 size_16 marr_30">发布时间：{{info.create_time}}</div>
							<div class="c61 size_16 marr_30">来源：{{info.source}}</div>
							<div class="c61 size_16 marr_30">访问量：{{info.click_num}}</div>
						</div>

						<div class="mart_20 size_14 fnf line_heihgt30" v-html="info.content"></div>
						
						<!-- 附件下载 -->
						<div class="mart_30 width" v-if="info.file.length > 0">
							<div class="border_min_title">附件下载</div>
							<div class="mart_10">
								<div v-for="item in info.file"
									class="flex align_center jus_content cursor padtb_20 border_bottom col_eff " 
									@click="download(item.path)">
									<div class="size_14 ttt font width_80 text_line1" :title="item.name">{{item.name}}</div>
									<div class="size_14 c61 font">点击下载</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>

		<el-backtop target=".announcedetails">
			<i class="el-icon-caret-top"></i>
		</el-backtop>

		<!-- 尾部 -->
		<app-footer @phone="getPhone" />
	</div>
</template>

<script>
	import Header from '@/components/Header/header.vue'
	import Footer from '@/components/Footer/footer.vue'

	export default {
		data() {
			return {
				keyword: '',
				loading: false,
				announceList: [],
				page: 1,
				limit: 10,
				// 总数
				count: 0,
				// 详情
				info: {},
				arr: [],
				qid: '',
				empty: false,
				offsetHeight: 0,
				phone: ''
			}
		},
		components: {
			'app-header': Header,
			'app-footer': Footer
		},
		computed: {
			noMore() {
				return this.arr.length < this.limit
			},
			disabled() {
				return this.loading || this.noMore
			}
		},
		methods: {
			// 附件下载
			download(url) {
				location.href = url
			},
			getPhone(phone) {
				this.phone = phone
			},
			// 获取头部高度
			getHeight(data) {
				this.offsetHeight = data
			},
			searchData() {
				this.page = 1
				this.announceList = []

				this.getAnnouncePort()
			},
			// 通知公告
			getAnnouncePort() {
				let data = {
					type: 2,
					page: this.page,
					limit: this.limit,
					keyword: this.keyword
				}

				this.$get('/news_list', data).then(res => {
					this.arr = res.result ? res.result : []

					if (res.result) {
						this.announceList = this.announceList.concat(res.result)

						this.count = res.count
						this.empty = false

						if (!this.qid) {
							this.qid = this.announceList[0].id
						}

						this.getInfoPort()
					} else {
						if(this.announceList.length > 0) {
							this.loading = false
							return
						}
						
						this.announceList = []
						this.empty = true
					}

					this.loading = false
				})
			},
			// 滚动分页
			infiniteData() {
				this.loading = true

				this.page += 1

				this.getAnnouncePort()
			},
			// 详情
			getInfoPort() {
				let data = {
					id: this.qid,
					type: 1
				}

				this.$get('/click_setInc', data).then(res => {
					let data = {
						id: this.qid
					}

					this.$get('/news_info', data).then(ress => {
						this.info = ress.result
					})
				})
			},
			// 点击左边展示详情
			setInfo(id) {
				this.qid = id

				this.getInfoPort()
			}
		},
		mounted() {
			if (this.$route.query.ids) {
				this.qid = this.$route.query.ids
			}

			this.getAnnouncePort()
		}
	}
</script>

<style scoped>
	@import url("./announcedetails.css");
	.col_eff{
		color:#0D8EFF!important;
	}
</style>
